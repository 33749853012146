@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

:root {
  --red-color: #bf1116;
  --light-grey: #2a2a2a;
  --dark-grey: #1c1c1c;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans 3", sans-serif;
  font-weight: 400;
  letter-spacing: 0.45px;
}


.modal {
  position: relative;
  padding: 8%;
  padding-top: 10%;
  background: var(--light-grey);
  color: white;
  line-height: 170%;
}

a {
  color: white;
}

.popup-content {
  background-color: transparent !important;
  padding: 0 !important;
  border: 0px !important;
  max-width: 400px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--red-color);
  cursor: pointer;
  outline: none;
}

.close:hover,
.close:focus {
  color: var(--red-color);
  outline: none; 
}

.container {
  position: relative;
}

.disable-click {
  width: 100px;
  height: 100vh;
  top: 0;
  right:  5%;
  background-color: transparent;
  opacity: 0.2;
  position: absolute;
  z-index: 999;
}

@media only screen and (max-width: 700px) {
  .disable-click {
    display: none;
  }
}
